<template>
    <div class="discount-bar-component" :class="[orderSettings.discounts.open ? 'active' : '', viewOnly && !canEditSales ? 'disabled': '']">
        <div class="settings-sidebar-layer" @click="closeBar"></div>
        <div class="settings-sidebar">
            <div class="settings-sidebar-head">
                <img src="@/assets/svg/discount.svg">
                <span class="name">{{ $t('common.discounts.sidebar.title') }}</span>
                <button type="button" @click="closeBar">
                    <md-icon>keyboard_arrow_right</md-icon>
                </button>
            </div>

            <div class="settings-sidebar-body">
<!--                <template v-if="!isOffer && !loading">-->
<!--                    <div class="radio">-->
<!--                        <input type="radio" name="r-dc" id="dc2" value="2" v-model="discountType">-->
<!--                        <label for="dc2">-->
<!--                            <span class="text">{{ $t('common.discounts.type.sale') }}</span>-->
<!--                            <span class="bg-layer"></span>-->
<!--                        </label>-->
<!--                    </div>-->

<!--                    <div class="radio">-->
<!--                        <input type="radio" name="r-dc" id="dc5" value="5" v-model="discountType">-->
<!--                        <label for="dc5">-->
<!--                            <span class="text">{{ $t('common.discounts.type.base') }}</span>-->
<!--                            <span class="bg-layer"></span>-->
<!--                        </label>-->
<!--                    </div>-->
<!--                </template>-->

                <div class="radio" v-if="groupDiscounts.length > 0 && !loading">
                    <input type="radio" name="r-dc" id="dc3" value="3" v-model="discountType" :disabled="viewOnly">
                    <label for="dc3">
                        <span class="text">{{ $t('common.discounts.type.group') }}</span>
                        <span class="bg-layer"></span>
                    </label>
                </div>

                <div class="discounts-container" :class="{'active' : discountType == 3 }" v-if="groupDiscounts.length > 0 && !loading">
                    <div class="label-row" v-if="isOffer && isWorkerOffer">
                        <span>{{ $t('common.discounts.sales') }}</span>
                        <span>{{ $t('common.discounts.purchase') }}</span>
                    </div>
                    <div class="label-row" v-else>
                        <span v-if="isOffer">{{ $t('common.discounts.buyer') }}</span>
                        <span v-if="!isOffer"></span>
                        <span>{{ $t('common.discounts.dealer') }}</span>
                    </div>

                    <div class="input-row" v-for="(discount, index) in groupDiscounts" :key="discount.id">
                        <div class="name">{{ discount.name }}</div>
                        <div class="input-wrapper" v-if="isOffer && isWorkerOffer">
                            <input
                                v-model="discount.sales"
                                :disabled="purchaseMargin"
                                @input="setDiscountData()"
                                :class="{'negative' : discount.sales < 0}"
                                type="number"
                                class="small"
                                v-zerohandling
                                step='0.01'
                                min="0"
                                max="100"
                                :tabindex="index + 1">
                                %
                        </div>
                        <div class="input-wrapper">
                            <input
                                v-model="discount.buyer"
                                :disabled="dealerMargin || isWorkerOffer"
                                @input="setDiscountData()"
                                :class="{'negative' : discount.buyer < 0}"
                                type="number"
                                class="small"
                                v-zerohandling
                                step='0.01'
                                min="0"
                                max="100"
                                :tabindex="index + 1"
                                v-if="isOffer">
                                <template v-if="isOffer">%</template>
                        </div>
                        <div class="input-wrapper" v-if="!isOffer || !isWorkerOffer">
                            <input
                                v-model="discount.dealer"
                                @input="writeBuyerDiscounts()"
                                type="number"
                                class="small"
                                v-zerohandling
                                step='0.01'
                                min="0"
                                max="100"
                                :tabindex="groupDiscounts.length + index + 1"
                                >%
                        </div>
                    </div>

                    <div class="dealer-margin" v-if="isOffer">
                        <template v-if="isWorkerOffer">
                            <div class="input-wrapper">
                                <div class="checkbox">
                                    <input
                                        v-model="purchaseMargin"
                                        @change="writeSalesDiscounts()"
                                        id="purchase-margin-1"
                                        type="checkbox">
                                    <label for="purchase-margin-1">{{ $t('common.discounts.sidebar.field.purchase_margin.label') }}</label>
                                    <div class="text-info">{{ $t('common.discounts.sidebar.text.purchase_margin') }}</div>
                                </div>
                            </div>
                            <div class="input-wrapper net">
                                <input
                                    v-model="purchaseMarginAmount"
                                    @input="writeSalesDiscounts()"
                                    :disabled="!purchaseMargin"
                                    type="number"
                                    class="small"
                                    v-zerohandling
                                    step='0.01'
                                    min="0"
                                    max="100">%
                                <span class="text-margin">
                                {{ $t('common.discounts.sidebar.field.purchase_margin_amount.label') }}
                                <span>{{ purchaseNetAmount | formatPrice }}</span>Ft
                            </span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="input-wrapper">
                                <div class="checkbox">
                                    <input
                                        v-model="dealerMargin"
                                        @change="writeBuyerDiscounts()"
                                        id="dealer-margin-1"
                                        type="checkbox">
                                    <label for="dealer-margin-1">{{$t('common.discounts.sidebar.field.dealer_margin.label') }}</label>
                                    <div class="text-info">{{ $t('common.discounts.sidebar.text.dealer_margin') }}</div>
                                </div>
                            </div>
                            <div class="input-wrapper net">
                                <input
                                    v-model="dealerMarginAmount"
                                    @input="writeBuyerDiscounts()"
                                    :disabled="!dealerMargin"
                                    type="number"
                                    class="small"
                                    v-zerohandling
                                    step='0.01'
                                    min="0"
                                    max="100">%
                                <span class="text-margin">
                                {{ $t('common.discounts.sidebar.field.dealer_margin_amount.label') }}
                                <span>{{ marginNetAmount | formatPrice }}</span>Ft
                            </span>
                            </div>
                        </template>
                    </div>
                </div>

                <template v-if="!loading">
                    <div class="radio">
                        <input type="radio" name="r-dc" id="dc4" value="4" v-model="discountType" :disabled="viewOnly">
                        <label for="dc4">
                            <span class="text">{{ $t('common.discounts.type.product') }}</span>
                            <span class="bg-layer"></span>
                        </label>
                    </div>
                    <p class="radio__description">{{ $t('common.discounts.text.product') }}</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import { ZeroHandling } from '@/plugins/directives'
import { round, getTotalPrices } from '@/plugins/helpers'

Vue.use(MdIcon)

export default {
    name: 'DiscountBar',
    props: ['loading', 'isWorkerOffer'],

    data() {
        return {
            groupDiscounts: [],
            discountType: null,
            dealerMargin: false,
            dealerMarginAmount: 0,
            purchaseMargin: false,
            purchaseMarginAmount: 0
        }
    },

    watch: {
        'loading': {
            handler: function (val) {
                if(!val) {
                    this.groupDiscounts = this.discounts.groupDiscounts
                    this.discountType = this.discounts.discountType

                    this.dealerMargin = this.discounts.dealerMargin
                    this.dealerMarginAmount = this.discounts.dealerMarginAmount

                    this.purchaseMargin = this.discounts.purchaseMargin
                    this.purchaseMarginAmount = this.discounts.purchaseMarginAmount

                    if(this.dealerMargin && this.dealerMarginAmount >= 0 && this.isOffer && !this.isWorkerOffer) {
                        this.writeBuyerDiscounts()
                    }

                    if(this.purchaseMargin && this.purchaseMarginAmount >= 0 && this.isOffer && this.isWorkerOffer) {
                        this.writeSalesDiscounts()
                    }
                }
            }
        },

        'orderSettings.discounts.open': {
            handler: function(val) {
                if (val) {
                    this.groupDiscounts = this.discounts.groupDiscounts
                    this.discountType = this.discounts.discountType

                    this.dealerMargin = this.discounts.dealerMargin
                    this.dealerMarginAmount = this.discounts.dealerMarginAmount

                    this.purchaseMargin = this.discounts.purchaseMargin
                    this.purchaseMarginAmount = this.discounts.purchaseMarginAmount
                } else {
                    this.setDiscountData()
                }
            }
        },

        'discountType': {
            handler: function() {
                this.setDiscountData()
            }
        }
    },

    methods: {
        closeBar() {
            this.$store.commit('closeSettingsSidebar', 'discounts')
        },

        writeBuyerDiscounts() {
            if (this.dealerMargin && this.dealerMarginAmount >= 0 && this.isOffer) {
                this.groupDiscounts.forEach((element, index) => {
                    let newBuyerAmount = round((100 * (1 - ((1 - element.dealer * 0.01) * (1 + this.dealerMarginAmount * 0.01)))), 2)
                    if (newBuyerAmount < 0) {
                        newBuyerAmount = 0
                    }

                    Vue.set(this.groupDiscounts[index], 'buyer', newBuyerAmount)
                })
            }

            this.setDiscountData()
        },

        writeSalesDiscounts() {
            if (this.purchaseMargin && this.purchaseMarginAmount >= 0 && this.isOffer) {
                this.groupDiscounts.forEach((element, index) => {
                    let newSalesAmount = round((100 * (1 - ((1 - element.buyer * 0.01) * (1 + this.purchaseMarginAmount * 0.01)))), 2)
                    if (newSalesAmount < 0) {
                        newSalesAmount = 0
                    }

                    Vue.set(this.groupDiscounts[index], 'sales', newSalesAmount)
                })
            }

            this.setDiscountData()
        },

        setDiscountData() {
            this.$store.commit('updateOfferDiscounts', {
                groupDiscounts: this.groupDiscounts,
                discountType: Number(this.discountType),
                dealerMargin: this.dealerMargin,
                dealerMarginAmount: this.dealerMarginAmount,
                purchaseMargin: this.purchaseMargin,
                purchaseMarginAmount: this.purchaseMarginAmount
            })

            if (Number(this.discountType) === 3 ) {
                this.$store.commit('updateProductDiscounts', this.$store.state.discounts)
            }

            this.$store.commit('calculateTransportsData')
        }
    },

    directives: { ZeroHandling },

    computed: {
        ...mapGetters(['orderSettings', 'discounts', 'orderData', 'viewOnly', 'canEditSales']),

        marginNetAmount() {
            let totalPrices = getTotalPrices(this.orderData.categories, this.discountType)
            return totalPrices.buyerPriceNet - totalPrices.dealerPriceNet
        },

        purchaseNetAmount() {
            let totalPrices = getTotalPrices(this.orderData.categories, this.discountType)
            return totalPrices.salesPriceNet - totalPrices.buyerPriceNet
        },

        isOffer() {
            return this.$route.name === 'OfferCategory' || this.$route.name === 'NewOfferCategory' || this.$route.name === 'NewRelatedOffer'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/settings-sidebar.scss';

.discount-bar-component {
    .settings-sidebar-body {
        .radio {
            position: relative;
            margin: 15px auto;
            width: 100%;

            &:after {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background: $color-gray-bg-2;
            }

            label {
                &:before,
                &:after,
                .text {
                    z-index: 5;
                }

                .text {
                    position: relative;
                }

                .bg-layer {
                    position: absolute;
                    background: $color-white;
                    height: 100%;
                    width: calc(100% + 40px);
                    right: -10px;
                    top: 0;
                    z-index: 1;
                }
            }

            &__description {
                font-size: 13px;
                margin-bottom: 15px;
            }

            input:disabled ~ label {
                opacity: 1;

                &:before,
                &:after,
                .text {
                    opacity: .85;
                }
            }
        }

        .discounts-container {
            max-height: 0;
            transition: max-height 0.15s ease-out;
            overflow: hidden;
            padding-left: 30px;

            &.active {
                max-height: 1000px;
                transition: max-height 0.25s ease-in;
            }

            .label-row {
                color: $color-gray-5;
                font-size: 13px;
                font-weight: bold;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 15px;

                span {
                    &:nth-child(1) {
                        flex-basis: 95px;
                    }

                    &:nth-child(2) {
                        flex-basis: 90px;
                    }
                }
            }

            .input-row {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 9px auto;

                .name {
                    flex-basis: calc(100% - 185px);
                    font-weight: bold;
                    font-size: 13px;
                }

                .input-wrapper {
                    &:nth-child(2) {
                        flex-basis: 95px;
                    }

                    &:nth-child(3) {
                        flex-basis: 90px;
                    }

                    input {
                        display: inline-block;
                        width: 64px;
                        margin-right: 5px;
                        text-align: center;

                        &.negative {
                            color: $color-red-1;
                        }
                    }
                }
            }

            .dealer-margin {
                margin: 25px 0 20px;

                .text-info {
                    margin-top: 5px;
                    margin-left: 28px;
                }

                .net {
                    margin-top: 15px;

                    input {
                        display: inline-block;
                        width: 64px;
                        margin-right: 5px;
                        text-align: center;
                    }

                    .text-margin {
                        display: inline-block;
                        margin-left: 20px;
                        font-size: 13px;

                        span {
                            display: inline-block;
                            margin: 7px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
</style>
